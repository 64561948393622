<template>
  <div class="overlayer">
    <aside class="rightSidebar" :style="`${width};${padding}`">
      <div class="flex-grow">
        <div
          class="text-lg flex text-romanSilver font-bold pb-3 "
          v-if="hasTitleSlot"
          style="border-bottom: 0.5px solid #878E99; margin: 1.125rem 0"
        >
          <div @click="$emit('back')">
            <back-button variant="secondary" v-if="backButton" class="p-0" />
          </div>
          <slot name="title" />
        </div>
        <div style="height:100%" v-if="loadingData">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div
            v-if="hasSubTitleSlot"
            style="margin: 1.125rem 0; border-bottom: 1px dashed #878E99"
          >
            <slot name="subtitle" />
          </div>
          <slot />
        </div>
      </div>
      <div>
        <div
          style="margin: 41px 0 1.125rem 0 "
          class="footer flex flex-col"
          v-if="showFooter"
        >
        <slot name="progress" />
        <div class="flex">
          <div v-if="closeButton" :class="buttonClasses">
            <Button
              width="7.875rem"
              @click="$emit('close')"
              class=" font-semibold"
            >
              Close
            </Button>
          </div>
          <div class="flex flex-grow" v-else>
            <Button
              :width="submitButtonWidth"
              @click="disabledButton ? '' : $emit('submit')"
              :disabled="disabledButton"
              :class="`${buttonClass}`"
              :style="
                disabledButton
                  ? `background-color : bg-dynamicBackBtn; opacity: 0.5`
                  : ``
              "
            >
              {{ submit }}
            </Button>
            <Button width="7.875rem" @click="$emit('close')">
              {{ cancel }}
            </Button>
          </div>
          <div v-if="hasFooter">
            <slot name="footer" />
          </div>
        </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import noScrollMixin from "@/utilities/noScrollMixin";
import Button from "./Button";

export default {
  mixins: [noScrollMixin],
  components: { Button, BackButton },
  props: {
    buttonClass: {
      type: String,
      default: "none"
    },
    width: {
      type: String,
      default: "width:38.5rem"
    },
    padding: {
      type: String,
      default: "padding: 0 2.25rem"
    },
    submitButtonWidth: {
      type: String,
      default: "7.875rem"
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    submit: {
      type: String,
      default: "Send"
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    closeButtonClasses: {
      type: String,
      default: ""
    },
    disabledButton: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: String,
      default: "Cancel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    backButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loadingData: this.loading
    };
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasSubTitleSlot() {
      return !!this.$slots.subtitle;
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
    buttonClasses() {
      return [
        'flex',
        this.closeButtonClasses === "" ? 'flex-grow' : this.closeButtonClasses,
        // Add more classes here if needed
      ]
    }
  },
  watch: {
    loading() {
      this.loadingData = this.loading;
    }
  }
};
</script>

<style lang="scss">
.rightSidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  height: 100%;
  background: #ffffff;
  color: #321c3b;
  box-shadow: 0px 10px 15px rgba(8, 7, 8, 0.1),
    0px 4px 6px rgba(45, 49, 66, 0.05);
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.footer {
  width: 100%;
  text-align: center;
}
.overlayer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.button {
  padding: 12px, 15px, 12px, 15px;
}
.button:focus {
  outline: none;
}

button[disabled] {
  cursor: wait;
}
</style>
