<template>
  <div
    class="fixed flex items-center justify-center"
    style="background: rgba(0, 0, 0, .5); width: 100%; height: 100%; top: 0; left: 0; z-index: 9999;"
  >
    <slot />
  </div>
</template>

<script>
import noScrollMixin from "@/utilities/noScrollMixin";

export default {
  mixins: [noScrollMixin],
  name: "Modal"
};
</script>

<style></style>
