<template>
  <validation-observer rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="onClose()"
      submit="Submit"
      :loading="isLoading"
      :disabled-button="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <back-button @onClick="onClose()" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            {{ title }}
          </span>
        </div>
      </template>
      <template>
        <EmployeeDetails
          :employee-info="employeeInfo"
          title="Employee Details"
        />
      </template>


      <template>
        <div class="w-full flex flex-col justify-start items-start gap-4">
          <div class="w-full flex flex-col justify-start gap-2">
            <p class="text-jet text-sm mr-5">Effective Date</p>
            <validation-provider class="w-full" name="Effective date" :rules="{required: true}" v-slot="{ errors }">
              <div :class="errors[0] ? 'border border-desire rounded-md' : 'date-flex'">
                <datepicker
                  placeholder="--Start Date--"
                  input-class="date-input"
                  style="width:100%;"
                  :rules="['required']"
                  v-model="payload.effectiveDate"
                />
              </div>
              <small class="text-desire">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="w-full text-darkPurple">
            <c-select
              placeholder="--Select--"
              :options="locationOptions"
              label="Office Location"
              variant="w-full"
              :rules="['required']"
              v-model="payload.newOfficeId"
              @input="onGetPayBandByOrgParams"
            />
          </div>
          <div class="w-full">
            <c-select
              placeholder="--Select--"
              :options="designationOptions"
              label="New Designation"
              variant="w-full"
              :rules="['required']"
              v-model="payload.newDesignationId"
              @input="onChangeDesignation"
            />
          </div>
          <div class="w-full text-darkPurple">
            <div class="text-sm mb-2 text-jet">
              Job Level
            </div>
            <div class="payInfo payInfo_fakeInput text-center">
              <span v-if="payload.newLevel && payload.newLevel.name">
                {{ payload.newLevel.name }}
              </span>
              <span v-else>--Generated--</span>
            </div>
          </div>
          <div class="w-full text-darkPurple">
            <div class="text-sm mb-2 text-jet">
              Pay Band/Range
            </div>
            <div class="payInfo payInfo_fakeInput text-center">
              <span v-if="isFetchingPayBand">Please wait...</span>
              <template v-else>
                <span v-if="Object.values(payload.payband).length">
                  {{ convertToCurrency(payload.payband.rangeMinimum) }} -
                  {{ convertToCurrency(payload.payband.rangeMaximum) }}
                </span>
                <span v-else>--Generated--</span>
              </template>
            </div>
          </div>
          <div class="w-full text-darkPurple">
            <span class="text-sm mb-2 text-jet">New Manager</span>
            <div class="payInfo payInfo_fakeInput text-center">
              <span v-if="newManager.fname">{{ newManager.fname }} {{ newManager.lname }}</span>
              <span v-else-if="newManager.name">{{ newManager.name }}</span>
              <span v-else>--Generated--</span>
            </div>
          </div>
          <div class="w-full text-darkPurple">
            <c-text
              placeholder="--Enter--"
              label="New Gross Annual Salary(₦)"
              variant="w-full"
              :rules="['required']"
              v-model="payload.newAnnualSalary"
            />
            <template v-if="payload.payband && Object.keys(payload.payband).length && payload.newAnnualSalary">
              <small class=" text-flame" v-if="payload.newAnnualSalary < payload.payband.rangeMinimum">
                <span>* New annual gross salary is less than pay range</span>
              </small>
              <small class=" text-flame" v-if="payload.newAnnualSalary > payload.payband.rangeMaximum">
                <span>* New annual gross salary is more than pay range</span>
              </small>
            </template>
          </div>
        </div>
      </template>
      <template>
        <Modal v-if="isPaybandWarning" class="">
          <Card class=" p-2 px-3 flex flex-col gap-3 max-w-md">
            <template>
              <div class="border-b border-romanSilver">
                <p class="text-jet font-bold text-base">Action Required!</p>
              </div>
              <div class="w-full flex flex-col justify-start gap-2">
                <div class="relative mb-5 w-full">
                  <div class="w-full px-5 py-2 flex self-center mb-2 rounded-md bg-yellow-100 border-carrotOrange border">
                    <div class="flex justify-start items-center gap-2">
                      <Icon icon-name="icon-info" size="xs" class="text-carrotOrange" />
                      <p class="text-carrotOrange w-full rounded-md text-center text-sm">
                        <span v-if="payload.newAnnualSalary < payload.payband.rangeMinimum">
                          New annual gross salary is less than pay range
                        </span>
                        <span v-if="payload.newAnnualSalary > payload.payband.rangeMaximum">
                          New annual gross salary is more than pay range
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="w-full flex flex-col justify-start items-start gap-1">
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-semibold text-sm text-jet">Salary Range:</p>
                      <p class="font-bold text-sm leading-5 text-jet">
                        <span v-if="payload.payband">
                          {{ convertToCurrency(payload.payband.rangeMinimum) }}
                            -
                          {{ convertToCurrency(payload.payband.rangeMaximum) }}
                        </span>
                        <span v-else>-</span>
                      </p>
                    </div>
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-semibold text-sm text-jet">Current Annual Gross:</p>
                      <p class="font-bold text-sm text-jet">
                        {{ convertToCurrency(employeeInfo.grossPay) }}
                      </p>
                    </div>
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-semibold text-sm text-jet">New Annual Gross:</p>
                      <p class="font-bold text-sm text-jet">
                        {{ convertToCurrency(payload.newAnnualSalary) }}
                      </p>
                    </div>
                  </div>
                </div>
                <p>Do you wish to continue?</p>
                <div class="flex justify-end items-center gap-2">
                  <Button
                    label="Cancel"
                    class="button-class button-class-secondary"
                    @onClick="isPaybandWarning = false"
                    :disabled="isLoading"
                  />
                  <Button
                    class="button-class button-class-pimary btn-bg"
                    label="Continue"
                    @onClick="onSubmit"
                    :disabled="isLoading"
                  />
                </div>
              </div>
            </template>
          </Card>
        </Modal>
      </template>
    </RightSideBar>
  </validation-observer>
</template>

<script>
  import * as _ from "lodash"
  import "vue-select/dist/vue-select.css"
  import { format } from "date-fns"
  import Datepicker from "vuejs-datepicker"
  import { ValidationObserver, ValidationProvider } from "vee-validate"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import CSelect from "@scelloo/cloudenly-ui/src/components/select"
  import CText from "@scelloo/cloudenly-ui/src/components/text"
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import RightSideBar from "@/components/RightSideBar"
  import Modal from "@/components/Modal"

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      Datepicker,
      BackButton,
      RightSideBar,
      CSelect,
      Button,
      CText,
      Card,
      Modal,
      EmployeeDetails: () => import("./details"),
    },
    props: {
      title: {
        type: String,
        default: 'Promote'
      },
    },
    data(){
      return {
        isLoading: false,
        isFetchingPayBand: false,
        isPaybandWarning: false,
        isOpen: false,
        designation: {},
        employeeInfo: {},
        promotion: {},
        queryString: '',
        designationOptions: [],
        paybandOptions: [],
        locationOptions: [],
        employeesOptions: [],
        paybandByLevelId: {},
        newManager: {},
        payload: {
          effectiveDate: '',
          newDesignationId: '',
          newAnnualSalary: '',
          newManagerId: '',
          newOfficeId: '',
          functionId: '',
          newLevel: {},
          payband: {}
        },
      }
    },
    methods: {
      toggle({ employeeInfo, promotion }){
        this.employeeInfo = employeeInfo
        this.isOpen = true

        if (promotion) {
          this.payload = {...this.payload, ...promotion}
          this.onGetPayBandByOrgParams()
        }
      },
      onSubmit() {
        const {rangeMinimum, rangeMaximum} = this.payload.payband
        const newGross = this.payload.newAnnualSalary
        const isInRange = _.inRange(newGross, rangeMinimum, rangeMaximum)
        const isPayband = _.size(_.keys(this.payload.payband))
        if(!isInRange && !!isPayband && !this.isPaybandWarning){
          this.isPaybandWarning = true
          return undefined
        }

        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          initiatedBy: this.$AuthUser.id,
          userId: this.employeeInfo.userId,
          newLevelId: this.payload.newLevel ? this.payload.newLevel.id : null,
          newOfficeId: this.payload.newOfficeId,
          functionId: this.payload.functionId,
          newManagerId: this.payload.newManagerId,
          newAnnualSalary: this.payload.newAnnualSalary,
          newDesignationId: this.payload.newDesignationId,
          effectiveDate: format(new Date(this.payload.effectiveDate), "yyyy-MM-dd"),
        }

        const query = this.payload.id ? '&update=true' : ''
        return this.$_employeeActionPromoteEmployee({ payload, query }).then(({ data }) => {
          const message = "Employee promotion request successfully sent for approval"
          this.$toasted.success(message, {  duration: 3000 })
          this.isPaybandWarning = false
          this.isLoading = false
          this.$emit('promoted', data)
          this.onClose()
          this.isOpen = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isPaybandWarning = false
          this.isLoading = false
        })
      },
      onClose(){
        Object.assign(this.$data.payload, this.$options.data().payload)
        this.employeeInfo = {}
        this.promotion = {}
        this.newManager = {}
        this.queryString = null
        this.$emit('close')
        this.isOpen = false
      },
      async onChangeDesignation(newDesignationId){
        const designation = this.designationOptions.find(i => (i.id === newDesignationId))
        const reportingTo = this.designationOptions.find(v => v.id === designation.reportingTo)
        this.newManager = reportingTo.hasUser || reportingTo.reportsTo || {}

        this.payload = {
          ...this.payload,
          newLevel: designation.level,
          functionId: designation.functionId,
          newManagerId: designation.reportingTo
        }
        this.onGetPayBandByOrgParams()
      },
      async onGetPayBandByOrgParams(){
        this.isFetchingPayBand = true
        await this.$_getPaygradeByOrgParameters({
          orgId: this.$orgId,
          levelId: this.payload.newLevel.id,
          designationId: this.payload.newDesignationId,
          functionId: this.payload.functionId,
          locationId: this.payload.newOfficeId
        }).then(({ data }) => {
          this.payload.payband = data.data || {}
          this.isFetchingPayBand = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isFetchingPayBand = false
          this.payload.payband = {}
        })
      },
      async onSearchEmployees(queryString, items) {
        clearTimeout(this.timeout);
        if (!queryString) {
          const qs = `&perPage=${items}`
          await this.$_getAllEmployeesWithQueries(qs).then(({ data }) => {
            this.employeesOptions = _.filter(data.data.employees,
              employee => employee.userId !== this.employeeInfo.userId
            )
          })
        }
        this.timeout = setTimeout(async () => {
          const qs = `&search=${queryString}&perPage=${items}`
          await this.$_getAllEmployeesWithQueries(qs).then(({ data }) => {
            this.employeesOptions = _.filter(data.data.employees,
              employee => employee.userId !== this.employeeInfo.userId
            )
          })
        }, 500)
      },
      async getOrgDesignations(){
        await this.$_getFunctionDesignations().then(({ data }) => {
          const designations = data.functions.map(d => d.designations)?.flat()
          this.designationOptions = designations.map((element) => ({
            ...element, disabled: (!!element.isSupervisory && !!element.hasUser)
          }))
        })
      },
      async getOrgOfficeLocations(){
        await this.$_getHumanarLocations().then(({ data }) => {
          this.locationOptions = data.outlets?.map((outlet) => ({
            ...outlet, value: outlet.id
          }))
        })
      },
    },
    async mounted() {
      this.isLoading = true
      await this.getOrgDesignations()
      await this.getOrgOfficeLocations()
      await this.onSearchEmployees('', 10)
      this.isLoading = false
    }
  }
</script>

<style lang="scss">
  .rightSidebar{
    width: 540px !important;
  }
  .payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>
<style scoped>
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.alert-style {
  position: relative;
  right: 0; top: -5px;
  box-shadow: none;
  background: #FFFFFF;
  color: #E99323;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgb(254 202 202);
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>

